
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
export default function NotFoundPage() {
  return (
    <div className="flex justify-center align-middle min-h-screen min-w-full">
      <h1 className="text-th-primary-medium self-center">
        <span className="text-4xl pr-4 mr-4 border-r-2 border-th-primary-light">404</span>
        <span className="text-3xl">{process.browser && document?.location?.pathname.startsWith('/resource/') ? 'Resource' : 'Page'} Not Found 🛸</span>
      </h1>
    </div>
  );
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  